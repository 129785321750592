import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import {
  CustomSection,
  CustomSectionContent,
  CustomSectionStickyHeading,
  CustomSectionStickyHeadingInner,
  CustomSectionStickyItemsWrap,
} from '../../components/CustomSection';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import StickySidebar from '../../components/StickySidebar';
import SamplesItem from '../../components/Samples/SamplesItem';
import Calculator from '../../components/Calculator';
import GradesBlock from '../../components/GradesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';

import './index.scss';


const OurSamplesPage = () => (
  <Layout
    pageWrapperClassName="our-samples-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Essay Samples - Essay Examples"
      meta={[
        {
          name: 'description',
          content: 'Takeaway Essay Samples provides professional writing services with guaranteed quality. Clients’ reviews speak for the efficiency of our writers and 24/7 availability of Take Away Essay Samples.',
        },

        { property: 'og:title', content: 'Our Sample Papers | TakeAwayEssay.com' },
        {
          property: 'og:description',
          content: 'Sample papers completed by our writers are the best way to see for yourself what kind of papers we deliver. Like the quality of our writing? Then place an order, tell us what exactly you need, and get a similar paper completed before the due date.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/our-samples.html' },
      ]}
    />

    <CustomSection className="custom-section_examples">
      <CustomSectionContent hasStickyElement>

        <CustomSectionStickyHeading className="examples-section__heading">
          <StickySidebar topSpacer={169}>
            <CustomSectionStickyHeadingInner className="examples-section__heading__inner">
              <VerticalTextBlock
                sectionClassName="examples"
                dotsStyle="style-4"
                text="Examples"
              />
              <div className="examples-section__heading__box">
                <h2 className="examples-section__heading__h2">
                  Samples
                </h2>
                <p className="examples-section__heading__paragraph">
                  Sometimes it’s better to show rather than tell. We’d like to showcase examples
                  of papers completed by
                  our writers to give you a better idea of the quality we deliver.
                </p>
              </div>
            </CustomSectionStickyHeadingInner>
          </StickySidebar>
        </CustomSectionStickyHeading>

        <CustomSectionStickyItemsWrap className="examples-section__items-wrap">
          <SamplesItem
            typeOfPaper="Persuasive essay"
            topicTitle="The Negative Impact of BREXIT on British Economy"
            academicLevel="Undergraduate"
            discipline="Economics"
            paperFormat="Harvard"
            sources="3"
            totalPages="2"
            pdfFileName="persuasive_essay_economics"
          />
          <SamplesItem
            typeOfPaper="Research paper"
            topicTitle="The Impact of the Digital Economy on Small Business"
            academicLevel="Master's"
            discipline="Business Studies"
            paperFormat="Harvard"
            sources="2"
            totalPages="4"
            pdfFileName="research_paper_business"
          />
          <SamplesItem
            typeOfPaper="Argumentative essay"
            topicTitle="Positive Criticism in Management"
            academicLevel="Undergraduate"
            discipline="Human Resources Management (HRM)"
            paperFormat="APA"
            sources="4"
            totalPages="3"
            pdfFileName="argumentative_essay_HRM"
          />
          <SamplesItem
            typeOfPaper="Persuasive essay"
            topicTitle="Family Planning Programs and Overpopulation"
            academicLevel="Master's"
            discipline="Sociology"
            paperFormat="MLA"
            sources="4"
            totalPages="3"
            pdfFileName="persuasive_essay_sociology"
          />
          <SamplesItem
            typeOfPaper="Argumentative essay"
            topicTitle="Information Technology as the Main Source of Economic Growth in India"
            academicLevel="Master's"
            discipline="Economics"
            paperFormat="MLA"
            sources="4"
            totalPages="3"
            pdfFileName="argumentative_essay_economics"
          />
          <SamplesItem
            typeOfPaper="Descriptive essay"
            topicTitle="How Dictionaries Are Created"
            academicLevel="Undergraduate"
            discipline="English 101"
            paperFormat="APA"
            sources="4"
            totalPages="3"
            pdfFileName="descriptive_essay_english101"
          />
          <SamplesItem
            typeOfPaper="Descriptive essay"
            topicTitle="Hydrological Significance of Mountains"
            academicLevel="Master's"
            discipline="Geology (and other Earth Sciences)"
            paperFormat="MLA"
            sources="4"
            totalPages="3"
            pdfFileName="descriptive_essay_geology"
          />
          <SamplesItem
            typeOfPaper="Descriptive essay"
            topicTitle="Economic Causes of Liberation from Soviet Rule in Poland"
            academicLevel="Undergraduate"
            discipline="History"
            paperFormat="APA"
            sources="4"
            totalPages="3"
            pdfFileName="descriptive_essay_history"
          />
          <SamplesItem
            typeOfPaper="Argumentative essay"
            topicTitle="Felony Disenfranchisement in the UK"
            academicLevel="Undergraduate"
            discipline="Law"
            paperFormat="Chicago"
            sources="2"
            totalPages="2"
            pdfFileName="argumentative_essay_law"
          />
          <SamplesItem
            typeOfPaper="Research paper"
            topicTitle="Robotics and the Future of Jobs"
            academicLevel="Master's"
            discipline="Technology"
            paperFormat="Chicago"
            sources="6"
            totalPages="4"
            pdfFileName="research_paper_technology"
          />
        </CustomSectionStickyItemsWrap>

      </CustomSectionContent>
    </CustomSection>

    <GradesBlock />
    <Calculator />

    <SeoTextSection>
      <SeoTextHeading>
        Essay examples: essay samples
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            If you are short on time and simply cannot afford doing profound research on yet another
            academic assignment, you might want some
            professional help. This is what we are here for.
          </p>
          <p>
            We offer academic assistance on all major disciplines for diverse education levels, be
            it high school, undergraduate, or graduate
            studies. We only hire qualified and experienced writers who can deliver your paper in no
            time, even if your deadline is 8 hours away.
            Besides, we are open to cooperation and seek constant improvement, so we will listen to
            all of your remarks and requirements to
            increase and maintain the quality of our work.
          </p>
          <p>
            If you need a helping hand with your academic assignments, consider hiring one of our
            writers. Why? For a number of good reasons. Our
            writing experts:
          </p>
          <ul className="dot-list">
            <li>
              always follow your instructions carefully
            </li>
            <li>
              actually conduct thorough research
            </li>
            <li>
              use credible sources
            </li>
            <li>
              write original, unique papers from scratch
            </li>
          </ul>
          <p>
            As a result, you get a properly formatted, meaningful, and literate paper in just about
            eight hours from the time you place your order.
            With our help, you will be able to deal with your homework loads quicker and more
            effectively.
          </p>

          <h2 className="h3">
            Why bother myself looking through essay samples at all?
          </h2>
          <p>
            It’s better to see something once than to hear about it a hundred times, as the saying
            goes. We all know people who ordered a
            ready-made paper on some unknown, shady website and never checked it out before
            submission. Sometimes, even a paper completed upon your
            request may be plagiarised, meaningless, or poorly written. This is why it is important
            to know what you are buying, and from whom.
          </p>
          <p>
            With this in mind, we have enabled our potential customers to review samples of the
            papers our content specialists can write. We want
            you to make sure that what you are going to order on our website is a product of high
            quality. Be it an essay, a research paper, a book
            review, a term paper, or a dissertation, you can feel confident about the outcome.
          </p>
          <p>
            What is more, if you want to make sure that a specific writer you hire from us will
            compose a flawless paper for you, we offer you to
            request a sample paper prepared by him or her. This will add a bit to the price, but
            you’ll sleep well, without worrying that he or she
            will not cope with your task.
          </p>


        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA>
      Want to get a similar
      <br />
      {' '}
      sample of the finest quality?
    </CTA>

  </Layout>
);

export default OurSamplesPage;
